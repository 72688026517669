import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { editJob, getJob, createJobPdf, getEvents, deleteEvent, editEvent } from 'actions/index';
import { getSalesman, getCustomer } from 'actions/user';
import { connect } from 'react-redux';
import { push } from 'modules/history';
import Icon from 'components/Icon';
import { Colors } from 'modules/theme';
import { Alert, Row, Col, Button, ButtonGroup, Navbar, Tab, Tabs } from 'react-bootstrap';
import { Formik, Form as FormikForm } from 'formik';

import Moment from 'moment';
import QuoteDetailsView from './QuoteDetailsView';
import BillingAddressView from './BillingAddressView';
import JobAddressView from './JobAddressView';
import GlassPiecesView from './GlassPiecesView';
import RepairDoorsView from './RepairDoorsView';
import NewDoorsView from './NewDoorsView';
import NotesView from './NotesView';
import EventListView from '../EventList/EventListView';

class EditJob extends Component {
  static propTypes = {
    fetchJob: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    onEditJob: PropTypes.func.isRequired,
    job: PropTypes.object,
    onCreateJobPdf: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const {
      match: { params },
      fetchJob,
      getSalesman,
      getCustomer,
    } = this.props;
    const { id } = params;
    fetchJob(id);

    getSalesman();
    getCustomer();
  }

  state = {
    showForm: true,
    listEvents: [],
    colorDetails: 'danger',
    colorEvents: 'light',
  };

  showEvents = () => {
    const {
      match: { params },
      fetchEvents,
      listEvent,
    } = this.props;
    const { id } = params;

    fetchEvents({ job_id: id });

    this.setState({
      showForm: false,
      listEvents: listEvent,
      colorDetails: 'light',
      colorEvents: 'danger',
    });
  };

  onSubmit = data => {
    const {
      onEditJob,
      match: { params },
    } = this.props;
    const { id } = params;
    onEditJob(id, data);
  };

  onPrint = () => {
    const {
      onCreateJobPdf,
      match: { params },
    } = this.props;
    const { id } = params;
    onCreateJobPdf(id);
  };

  render() {
    const {
      salesmans,
      csrs,
      submitJob,
      loading,
      token,
      job,
      removeEvent,
      fetchEvents,
      editEvent,
      loadingEvent,
      match: { params },
    } = this.props;
    const { id } = params;
    const jobTabs = {
      glassPieces: [],
      repairDoors: [],
      newDoors: [],
      images: [],
      sketchs: [],
      other:
        job?.other?.map(item => ({
          ...item,
          glassReminders: {
            glass_elevator: item.glassReminders?.glass_elevator || 'no',
            solar_film: item.glassReminders?.solar_film || 'no',
            solar_film_option_sag: item.glassReminders?.solar_film_option_sag || null,
            wet_seal: item.glassReminders?.wet_seal || 'no',
            wet_seal_option: item.glassReminders?.wet_seal_option || null,
            furniture: item.glassReminders?.furniture || 'no',
            walls_cielings: item.glassReminders?.walls_cielings || 'no',
            walls_cielings_option: item.glassReminders?.walls_cielings_option || null,
            blind_remove: item.glassReminders?.blind_remove || 'no',
            color_waiver: item.glassReminders?.color_waiver || 'no',
            damage_waiver: item.glassReminders?.damage_waiver || 'no',
            disclaimers: item.glassReminders?.disclaimers || 'no',
            lift_inside: item.glassReminders?.lift_inside || 'no',
            lift_outside: item.glassReminders?.lift_outside || 'no',
            ...item.glassReminders,
          },
          glassMaterials: {
            caulks:
              item.glassMaterials?.caulks.length > 0
                ? item.glassMaterials?.caulks
                : [{ amount: 0, value: '', notes: '' }],
            types:
              item.glassMaterials?.types.length > 0
                ? item.glassMaterials?.caulks
                : [{ amount: 0, value: '', notes: '' }],
            equipaments:
              item.glassMaterials?.equipaments.length > 0
                ? item.glassMaterials?.equipaments
                : [{ amount: 0, value: '', notes: '' }],
            channels:
              item.glassMaterials?.channels.length > 0
                ? item.glassMaterials?.channels
                : [{ amount: 0, value: '', notes: '' }],
            miscellaneous:
              item.glassMaterials?.miscellaneous.length > 0
                ? item.glassMaterials?.miscellaneous
                : [{ amount: 0, value: '', notes: '' }],
          },
        })) || [],
    };
    const { listEvents } = this.state;

    const initialValues = {
      ...jobTabs,
      ...job,
      other: jobTabs.other,
    };
    if (!this.state.showForm) {
      const date = Moment().toDate();

      var listCurrentEvent = listEvents.filter(element => {
        if (element.type == 0) {
          return Moment(date).isBefore(element.start_time);
        }
        return Moment(date).isBefore(element.updated_at);
      });

      var listPastEvent = listEvents.filter(element => {
        if (element.type == 0) {
          return !Moment(date).isBefore(element.start_time);
        }
        return !Moment(date).isBefore(element.updated_at);
      });
    }

    const header = (
      <Row style={{ marginTop: '20px', marginBottom: '20px' }}>
        <Col>
          <h2 className="align-items-center">
            <Icon
              fontSize={25}
              name="chevron-left"
              color={Colors.blue}
              customStyle={{ paddingRight: '20px' }}
              onClick={() => push('/dashboard/jobs')}
            />
            Edit Job
          </h2>
        </Col>
        <ButtonGroup className="d-none d-md-flex mr-1">
          <Button variant="outline-danger" size="lg" type="button" onClick={() => this.onPrint()}>
            <Row className="ml-3 mr-3">
              <i className="fas fa-file-pdf pr-3 pt-2" style={{ fontSize: 15 }} />
              <h4 className="p-1"> Download PDF</h4>
            </Row>
          </Button>
        </ButtonGroup>
      </Row>
    );

    const form = (
      <Formik enableReinitialize onSubmit={this.onSubmit} initialValues={initialValues}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isValid,
          setFieldValue,
          submitForm,
        }) => {
          const glassPiecesCount = values.glassPieces.length;
          const newDoorsCount = values.newDoors.length;
          const repairDoorsCount = values.repairDoors.length;
          const otherCount = values.other.length;

          return (
            <FormikForm>
              {/* Quote */}
              <QuoteDetailsView
                values={values}
                errors={errors}
                handleChange={handleChange}
                touched={touched}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                salesmans={salesmans}
                csrs={csrs}
              />
              {/* Billing Address */}
              <BillingAddressView
                values={values}
                errors={errors}
                handleChange={handleChange}
                touched={touched}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
              />
              {/* Job Address */}
              <JobAddressView
                values={values}
                errors={errors}
                handleChange={handleChange}
                touched={touched}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                isEdit
              />
              {/* NAVBAR */}
              <Row className="px-4 py-4 ml-1 mr-1" style={{ background: '#F8FBFF' }}>
                <Col xs={12} md={12} sm={12} xl={12}>
                  <Navbar>
                    <Navbar.Brand className="text-button">Select Job Info</Navbar.Brand>
                  </Navbar>
                </Col>
              </Row>
              <Row className="px-4 py-4 ml-1 mr-1" style={{ background: '#F8FBFF' }}>
                <Col xs={12} md={12} sm={12} xl={12}>
                  <Tabs defaultActiveKey="glassPieces" id="tabs">
                    <Tab
                      eventKey="glassPieces"
                      title={
                        <>
                          <Row className="p-4">
                            <h3 className="input-text mr-2">Glass</h3>
                            {glassPiecesCount >= 1 && (
                              <p
                                className="icon-button button-rounded mr-1"
                                style={{ background: '#0084EA', fontWeight: 'bold' }}
                              >
                                {glassPiecesCount}
                              </p>
                            )}
                          </Row>
                        </>
                      }
                    >
                      <GlassPiecesView
                        values={values}
                        errors={errors}
                        handleChange={handleChange}
                        touched={touched}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        token={token}
                      />
                    </Tab>
                    <Tab
                      eventKey="repairDoors"
                      title={
                        <>
                          <Row className="p-4">
                            <h3 className="input-text mr-2">Door Repair</h3>
                            {repairDoorsCount >= 1 && (
                              <p
                                className="icon-button button-rounded mr-1"
                                style={{ background: '#0084EA', fontWeight: 'bold' }}
                              >
                                {repairDoorsCount}
                              </p>
                            )}
                          </Row>
                        </>
                      }
                    >
                      <RepairDoorsView
                        values={values}
                        errors={errors}
                        handleChange={handleChange}
                        touched={touched}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        token={token}
                      />
                    </Tab>
                    <Tab
                      eventKey="newDoors"
                      title={
                        <>
                          <Row className="p-4">
                            <h3 className="input-text mr-2">New</h3>
                            {newDoorsCount >= 1 && (
                              <p
                                className="icon-button button-rounded mr-1"
                                style={{ background: '#0084EA', fontWeight: 'bold' }}
                              >
                                {newDoorsCount}
                              </p>
                            )}
                          </Row>
                        </>
                      }
                    >
                      <NewDoorsView
                        values={values}
                        errors={errors}
                        handleChange={handleChange}
                        touched={touched}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        token={token}
                      />
                    </Tab>
                    <Tab
                      eventKey="notes"
                      title={
                        <Row className="p-4">
                          <h3 className="input-text mr-2">Other</h3>
                          {otherCount >= 1 && (
                            <p
                              className="icon-button button-rounded mr-1"
                              style={{ background: '#0084EA', fontWeight: 'bold' }}
                            >
                              {otherCount}
                            </p>
                          )}
                        </Row>
                      }
                    >
                      <NotesView
                        values={values}
                        errors={errors}
                        handleChange={handleChange}
                        touched={touched}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        token={token}
                      />
                    </Tab>
                  </Tabs>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} sm={12} xl={12} className="mt-4 pr-1 ml-1">
                  {!isValid ? (
                    <Alert variant="danger" className="input-text">
                      There are missing required fields. Please fill all required fields to save or
                      submit this job.
                    </Alert>
                  ) : (
                    <Alert variant="success" className="input-text">
                      All required fields have been filled.
                    </Alert>
                  )}
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={12} sm={12} xl={12} className="mt-4 pr-1">
                  <ButtonGroup className="mr-4 float-right">
                    <Button size="lg" type="submit" className="button" variant="outline-primary">
                      Save
                    </Button>
                    <Button
                      onClick={() => {
                        setFieldValue('job_status', 'Submitted');
                        submitForm();
                      }}
                      variant="primary"
                      size="lg"
                      type="button"
                      className="float-right ml-2 button"
                    >
                      Submit Job
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </FormikForm>
          );
        }}
      </Formik>
    );

    const event = (
      <EventListView
        listCurrentEvent={listCurrentEvent}
        listPastEvent={listPastEvent}
        loading={loading}
        removeEvent={removeEvent}
        fetchEvents={fetchEvents}
        editEvent={editEvent}
        onContainer={false}
      />
    );

    return (
      <>
        {header}
        {this.state.showForm ? form : event}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    loading: state.job.loading,
    salesmans: state.user.salesmans,
    csrs: state.user.csrs,
    job: state.job.current,
    listEvent: state.event.listEvent,
    loading: state.event.loading,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    fetchJob: id => dispatch(getJob(id)),
    getSalesman: () => dispatch(getSalesman()),
    getCustomer: () => dispatch(getCustomer()),
    onEditJob: (id, data) => dispatch(editJob(id, data)),
    onCreateJobPdf: id => dispatch(createJobPdf(id)),
    fetchEvents: params => dispatch(getEvents(params)),
    removeEvent: id => dispatch(deleteEvent(id, { status })),
    editEvent: (id, data) => dispatch(editEvent(id, data)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(EditJob);
